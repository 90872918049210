@import "/src/Styles/vars";

.wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 300;
}

.modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  min-height: 180px;
  max-height: 100%;
  background-color: #fff;
  overflow: auto;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  height: 40px;
  &.is_title {
    border-bottom: 1px solid #e4e4e4;
  }
}

.inner {
  width: 100%;
  height: 100%;
  padding: 14px 30px 30px 30px;
}

.close_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  font-size: 3rem;
  color: #656565;
  cursor: pointer;
  transition: all 0.1s;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &:hover {
    filter: invert(53%) sepia(56%) saturate(2235%) hue-rotate(333deg)
      brightness(102%) contrast(101%);
  }
}

.title {
  font-family: Oswald, sans-serif;
  font-size: 2rem;
  line-height: 160%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0;
  &_small {
    color: #656565;
  }
}

@media (max-width: 575px) {
  .modal {
    min-height: 200px;
    width: calc(100% - 40px);
  }
  .inner {
    width: 100%;
    height: 100%;
    padding: 14px 20px 20px 20px;
  }
}
