.logo {
    width: 188px;
    height: 60px;
}

footer .logo {
    width: 188px;
    height: 111px;
}

@media (max-width: 960px) {
    .logo {
        width: 125px;
        height: 39px;
    }
}