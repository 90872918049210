@import "/src/Styles/vars";

.auth {
  max-width: 530px;
  width: 100%;
}

.btn {
  display: block;
  width: 250px;
  height: 42px;
  margin: 0 auto;
  background-color: $main;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  border: none;
  outline: none;
  cursor: pointer;
}

.help_links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 23px;
  margin-top: 20px;

  span:hover {
    font-weight: bold;
    cursor: pointer;
  }
}

.line {
  width: 100%;
  border: 1px solid #e4e4e4;
}

.enter {
  &__label {
    text-align: center;
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 43px;
    height: 43px;
    margin: 0 8.5px;
    background-color: #181818;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background-color: $main;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.warning {
  text-align: center;
  color: $main;
  width: 100%;
  height: 40px;
}

@media (max-width: 575px) {
  .btn {
    width: 100%;
    height: 52px;
  }
  .help_links {
    flex-direction: column;
    height: 50px;
  }
}
