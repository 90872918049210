@mixin error-fildes() {
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(185, 40, 40);
  text-align: center;
  font-size: 12px;
}

@mixin text-overflow($lines) {
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin textOverflowOneLine() {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
