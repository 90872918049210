@import "src/styles/vars";

.sliderButton {
  transition: all 0.1s;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: invert(50%) sepia(0%) saturate(2372%) hue-rotate(27deg)
      brightness(125%) contrast(77%);
  }
  &:hover {
    img {
      filter: none;
    }
  }
}

.sliderPrev {
  img {
    transform: rotate(90deg);
  }
}
.sliderNext {
  img {
    transform: rotate(-90deg);
  }
}
