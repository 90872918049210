.modal {
  // width: 630px;
  // height: 830px;
  max-width: 1200px;
  max-height: 100%;
}

.image {
  // width: 480px;

  margin: 0 auto;
  img {
    width: 100%;
    max-height: 80vh;
    object-fit: contain;
  }
}

.descr {
  text-align: center;
  margin-top: 60px;
  font-size: 2rem;
  font-weight: bold;
}

@media (max-width: 715px) {
  // .modal {
  //   width: 450px;
  //   height: 500px;
  // }
  // .image {
  //   width: 300px;
  // }
  .descr {
    margin-top: 20px;
  }
}

@media (max-width: 575px) {
  // .modal {
  //   width: 280px;
  //   height: 460px;
  // }
  // .image {
  //   width: 250px;
  // }
  .descr {
    font-size: 1.6rem;
    font-weight: normal;
    margin-top: 40px;
  }
}

@media (max-width: 360px) {
  // .modal {
  //   width: 260px;
  // }
}
