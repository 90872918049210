@import 'src/Styles/vars';

.count{
  text-align: center;
  &__btn{
    background-color: transparent;
    border: none;
    outline: none;
    margin: 5px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    &:hover{
      color: $main;
    }
  }
}

