@import "/src/Styles/vars";

.menu {
  display: flex;
  align-items: center;
}
.list {
  display: flex;
  column-gap: 30px;
  .hidepc {
    display: none;
  }
}

.link {
  color: #fff;

  font-weight: bold;
  font-size: 1.3rem;
  line-height: 15px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  width: 100%;
  &_text {
    &:hover {
      padding: 12px 0 9px;
      border-bottom: 3px solid $main;
      cursor: pointer;
    }
  }
}

.dropdown {
  display: none;
  position: absolute;
  background-color: #181818;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.item:hover {
  .dropdown {display: block;}
}

.active {
  .link_text {
    padding: 12px 0 9px;
    border-bottom: 3px solid $main;
  }
}

@media (max-width: 960px) {
  .link {
    font-size: 1.1rem;
  }
}
@media (max-width: 670px) {
  .list {
    column-gap: 15px;
  }
}

@media (max-width: 576px) {
  .hidemobile {
    display: none !important;
  }
  .hidepc {
    display: flex !important;
  }
  .menu {
    width: 100%;
    display: block;
  }
  .list {
    display: flex;
    flex-direction: column;
    // column-gap: 30px;
  }
  .item {
    width: 100%;
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e4e4e4;
    margin: 0;
    padding: 0 20px;
  }
  .link {
    color: #000;
    font-size: 1.4rem;
  }
}
