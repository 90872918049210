@import "src/Styles/vars";

.modal {
  text-align: center;
  max-width: 600px;
}

.text {
  margin: 20px 0;
  font-size: 2rem;
}

.btn {
  max-width: 260px;
  width: 100%;
  height: 46px;
  border: none;
  outline: none;
  background-color: $main;
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}
