@import "/src/Styles/vars";

.contacts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.5s;
}

.dropdown {
  margin: 0 10px;
  position: relative;
  &__list {
    position: absolute;
    top: 5px;
    left: 0;
  }
  &__link:hover {
    color: $main;
  }
}

.arrow {
  transition: all 0.3s;
  cursor: pointer;
  &.active {
    transform: rotate(180deg);
  }
}

@media (max-width: 960px) {
  .dropdown {
    display: none;
  }
  .arrow {
    display: none;
  }
}

@media (max-width: 576px) {
  .contacts {
    position: relative;
    width: 50%;
    justify-content: center;
    img {
      width: 12px;
    }
    padding: 0 5px;
  }
  .dropdown {
    position: static;
    display: block;
    a {
      color: #fff;
      font-size: 1.2rem;
    }
    &__list {
      background-color: #000;
      width: 100%;
      text-align: center;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
      }
    }
  }
  .arrow {
    display: block;
    padding-top: 3px;
  }
}

@media (max-width: 960px) {
  .dropdown {
    margin: 0 7px;
  }
}
