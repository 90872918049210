@import "/src/Styles/vars";

.title {
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  font-size: 4rem;
  line-height: 160%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: $black-text;
  margin: 0;
}

.white {
  color: #fff;
}

@media (max-width: 1280px) {
  .white {
    font-size: 3rem;
  }
}

@media (max-width: 800px) {
  .title {
    font-size: 3rem;
  }
}

@media (max-width: 576px) {
  .title {
    font-size: 30px;
  }
}
