@import '/src/Styles/vars';

.wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.language {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin: 0 3.5px;
    font-size: 1.2rem;
    line-height: 14px;
    text-transform: uppercase;
    color: #222222;
    cursor: pointer;
    &.active{
        color: $main;
        border: 1px solid $main;
    }
    
}
