@import 'src/Styles/vars';

.arrow{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  right: 20px;
  width: 41px;
  height: 48px;
  background-color: $main;
  transform: translateY(-50%);
  z-index: 100;
}

@media (max-width: 575px) {
  .arrow{
    display: none;
  }
}
