@import "/src/Styles/vars";

.footer {
  // margin-top: auto;
  position: relative;
  width: 100%;
  min-height: 388px;
  background-color: $black;
  &__top {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 30px 0 23px;
    color: #fff;
    a {
      color: #ddd;
      &:hover {
        color: #fff;
      }
    }
    &_right {
      display: flex;
      justify-content: space-between;
      width: 478px;
    }
  }
  &__bottom {
    min-height: 82px;
    border-top: 1px solid #fff;
    padding: 18px 0;
    font-weight: bold;
    font-size: 1rem;
    line-height: 150%;
    color: #ddd;
    span {
      text-transform: uppercase;
    }
  }
}

.title {
  font-size: 1.6rem;
  line-height: 150%;
  text-transform: uppercase;
  margin-bottom: 0;
}

.logo {
  max-width: 208px;
  font-size: 1.2rem;
  line-height: 160%;
  color: #bcbcbc;
  span {
    display: block;
    margin-top: 20px;
  }
}

.menu {
  width: 160px;
  &__link {
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: 11px;
    font-size: 1.4rem;
    line-height: 150%;
    transition: all 0.5s;
    &:hover {
      padding-left: 18px;
      font-weight: bold;
      &::before {
        content: "";
        position: absolute;
        top: 3px;
        left: 0;
        width: 14px;
        height: 14px;
        background: url("../../Assets/Icons/arrow-orange.svg") center / cover
          no-repeat;
        animation: arrow-move 1s;
      }
    }
  }
}

.contacts {
  max-width: 255px;
  &__list {
    &_item {
      display: flex;
      align-items: center;
      margin-bottom: 13px;
      &:hover {
        .contacts__list_img {
          animation: swing-move 1s infinite;
        }
      }
    }
    &_img {
      margin-right: 10px;
    }
  }
}

.bold {
  color: #fff;
  font-weight: bold;
}

.worktime {
  max-width: 215px;
  width: 100%;
  &__item {
    span {
      color: #fff;
      font-weight: bold;
    }
    margin-bottom: 12px;
    line-height: 1.4rem;
  }
}
.inner__form {
  width: 100%;
  height: 100%;
  display: flex;
}
.subscribe__input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;

  input {
    height: 100%;
    padding: 12px 15px;
  }
}
.subscribe {
  width: 382px;
  height: 210px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 27px 20px;
  color: #fff;
  font-weight: bold;
  line-height: 150%;
  &__form {
    width: 100%;
    height: 42px;
    margin-top: 20px;
    .privacy_info {
      text-align: center;
      padding: 30px 20px 20px 20px;
      font-size: 1.2rem;
      line-height: normal;
      margin: auto;
      a {
        color: #ff6e43;
        font-weight: 600;
      }
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 100%;
    border: none;
    outline: none;
    background-color: $main;
    cursor: pointer;
  }
}

.social {
  display: flex;
  align-items: center;
  &__item {
    margin-right: 20px;
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.5s;
    &:hover {
      background-color: $main;
      border-radius: 5px;
    }
  }
}

.link_up {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  background-color: rgba(255, 255, 255, 0.1);
  img {
    transform: rotate(-90deg);
  }
  &:hover {
    animation: move-up 2s infinite;
  }
}

@keyframes arrow-move {
  0% {
    transform: translateX(-15px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes swing-move {
  0% {
    transform: rotateZ(45deg);
  }
  25% {
    transform: rotateZ(0deg);
  }
  50% {
    transform: rotateZ(-45deg);
  }
  75% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(45deg);
  }
}

@keyframes move-up {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-5px);
  }
}

@media (max-width: 1340px) {
  .link_up {
    display: none;
  }
  .footer__top {
    &_right {
      width: 382px;
    }
  }
}

@media (max-width: 1220px) {
  .footer__top {
    &_right {
      display: none;
    }
  }
}

@media (max-width: 746px) {
  .footer__top {
    display: block;
  }
}

@media (max-width: 575px) {
  .link_up_mobile {
    position: absolute;
    top: 30px;
    right: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    background-color: rgba(255, 255, 255, 0.1);
    img {
      transform: rotate(-90deg);
    }
    &:hover {
      animation: move-up 2s infinite;
    }
  }
  .menu {
    width: 160px;
    &__link {
      margin: 0;
    }
  }
}

@media (min-width: 576px) {
  .link_up_mobile {
    display: none;
  }
}
.footer__bottom_text {
  margin: 0;
}
.footer__bottom_text_logo {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: center;
  font-size: 15px;
  a {
    color: #fff;
  }
}
