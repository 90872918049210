@import "/src/Styles/vars";

.menu {
  position: absolute;
  // top: 120px;
  width: 100%;
  height: 0;
  background-color: rgba(#000000, 0.65);
  z-index: 5;
  overflow: hidden;
  transition: all 0.5s;
  &.active {
    height: 100vh;
  }
  &__img {
    margin-right: 20px;

    object-fit: contain;
    width: 80px;
    height: 64px;
  }
  &__list {
    width: 100%;
    padding: 25px 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    transform: translateY(-200%);
    opacity: 0;
    transition: all 1s;
    &.active {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &__item {
    display: flex;
    align-items: flex-start;
  }
  &__title {
    margin-top: 0;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    &:hover {
      color: $main;
    }
  }
}
.wrapper {
  position: relative;
  width: 100%;
  // height: 0;
  opacity: 0;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  transition: all 0.5s;
  transform: translateY(-200%);
  &.active {
    // height: 508px;
    opacity: 1;
    transform: translateY(0);
    .right {
      background: url("../../../Assets/Images/Backgrounds/product_bg.jpg") center /
    cover no-repeat;
    }
  }
}

.submenu {
  &__link {
    font-size: 1.2rem;
    line-height: 14px;
    &:hover {
      font-weight: bold;
      color: $main;
    }
  }
}

.left {
  width: 100%;
  height: 100%;
}

.right {
  display: flex;
  align-items: center;
  min-width: 259px;
  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    background-color: $main;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 27px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #fff;
  }
}

.link {
  display: flex;
  align-items: center;
  position: absolute;
  right: 300px;
  bottom: 35px;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 150%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: $black-text;
  &_img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    margin-left: 12px;
    background-color: $main;
  }
}

@media (max-width: 1160px) {
  .menu {
    width: 100%;
    &__title {
      font-size: 1rem;
    }
    &__list {
      padding: 10px;
    }
    &__img {
      width: 80px;
    }
  }
  .right {
    min-width: 180px;
    &__label {
      font-size: 1.4rem;
    }
  }
}

@media (max-width: 960px) {
  .right {
    display: none;
  }
  .link {
    right: 30px;
  }
}
