.address{
    display: flex;
    align-items: center;
}

.label{
    margin-left: 10px;
}

@media (max-width: 760px) {
    .address{
        display: none;
    }
}

@media (max-width: 576px) {
    .address{
        display: flex;
    }
    .label{
        margin-left: 30px;
        font-weight: 500;
    }
}