@import "/src/Styles/vars";

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 259px;
  height: 42px;
  padding: 0 5px;
  background-color: $main;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  cursor: pointer;
}

.img {
  margin-left: 10px;
}

.active {
  animation: move 2s infinite;
}

@keyframes move {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0px);
  }
}

@media (max-width: 1160px) {
  .link {
    width: 180px;
    font-size: 1rem;
  }
  .img {
    width: 8px;
  }
}

@media (max-width: 576px) {
  .link {
    width: 50%;
    height: 100%;
    font-size: 1rem;
  }
  .img {
    width: 8px;
  }
}
@media (max-width: 375px) {
  .link {
    text-align: center;
  }
}
