@import "/src/Styles/vars";

.wrapperSearchbar {
  position: relative;
}
.searchbar {
  width: 400px;
  height: 34px;
  background: #f3f3f3;
}

.input {
  background-color: transparent;
  border: 1px solid transparent;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  transition: all 0.5s;
  outline: none;
  &:hover,
  &:focus {
    border: 1px solid $main;
  }
}

.btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

@media (max-width: 1160px) {
  .searchbar {
    width: 200px;
  }
}

@media (max-width: 575px) {
  .searchbar {
    width: 100%;
  }
}

.dropdown__list {
  background-color: #f7f7f7;
  position: absolute;
  top: calc(100% - 12px);
  left: 0;
  right: 0;
  max-height: 200px;
  padding: 5px 0;
  z-index: 10;
  overflow: auto;
  border: 1px solid #f1f1f1;
  border-top: none;
}
.wrapper_img {
  width: 100px;
  height: 100px;
  img {
    width: 100px;
    height: 100%;
    object-fit: contain;
  }
}

.wrapper_good {
  display: flex;
  column-gap: 10px;
  //   margin-bottom: 10px;
}
.name {
  display: block;
  margin-top: 5px;
  font-size: 18px;
  line-height: 20px;
}
.price {
  display: block;
  margin-top: 10px;
  font-size: 16px;
}

.link {
  display: block;
  margin-bottom: 5px;
  :hover {
    background-color: #fd764d;
  }
}
