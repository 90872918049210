@import "/src/Styles/vars";

.header {
  width: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 10;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 14px;
  color: #292929;
  &__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80px;
    margin-right: 10px;
  }
}

.bottom {
  width: 100%;
  height: 42px;
  background-color: $black;
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 42px;
    position: relative;
    z-index: 5;
  }
}

@media (max-width: 576px) {
  .header {
    display: none;
  }
}
