@import "/src/Styles/vars";

.table {
  width: 100%;
  table-layout: fixed;
  font-size: 1.4rem;
  overflow: auto;
  tr {
    min-height: 32px;
    width: 100%;
  }
  th,
  td {
    width: 100%;
    border: 1px solid #e4e4e4;
    padding: 5px 10px;
  }
  &__head {
    font-weight: bold;
    line-height: 130%;
    color: #ffffff;
    background-color: #8d8d90;
    text-align: left;
  }
  &__body {
  }
}

.count {
  text-align: center;
  &__btn {
    margin: 0 10px;
    border: none;
    outline: none;
    background-color: #fff;
    width: 18px;
    height: 18px;
    cursor: pointer;
    &:hover {
      color: $main;
    }
  }
}

@media (max-width: 890px) {
  .table {
    &__head {
      text-align: center;
    }
  }
}
