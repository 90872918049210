.header_mobile {
  position: relative;
  display: none;
  max-width: 576px;
  min-height: 80px;
  z-index: 20;
  position: fixed;
  z-index: 10;
  width: 100%;
  background: #fff;
}

.top {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  a.search {
    margin-right: 20px;
  }
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 36px;
  background-color: #000;
}

@media (max-width: 576px) {
  .header_mobile {
    display: block;
  }
}
