@import '/src/Styles/vars';

.profile{
    position: relative;
    min-width: 25px;
    cursor: pointer;
}

.status{
    position: absolute;
    top: -5px;
    left: 15px;
    width: 15px;
    height: 15px;
    padding: 2px;
    background-color: $main;
    border-radius: 30px;
}

.non_auth{
    display: flex;
    align-items: center;
}

.link{
    display: flex;
    align-items: center;
}

.name{
    display: inline-block;
}

.ava{
    margin-right: 20px;
}

@media (max-width: 576px){
    .profile{
        margin-right: 20px;
    }
}

@media (min-width: 577px){
    .name{
        display: none;
    }
}
