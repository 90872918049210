.table {
  padding-top: 20px;

  &_title {
    width: 50%;
    color: #8D8D91;
  }

  &_label {
    width: 50%;
  }

  &_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 27px;
    width: 100%;
    padding: 4px 10px;

    &:nth-child(odd) {
      background-color: #F1F1F1;
    }
  }
}