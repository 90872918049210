@import "src/Styles/vars";

.modal {
  max-width: 818px;
  width: 100%;
}

.top {
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
}

.img {
  // width: 100%;
  // width: 250px;
  // height: 250px;

  // img {
  //   object-fit: contain;
  //   width: 230px;
  //   height: 230px;
  // }
}

.table_main {
  margin-top: 10px;
  max-width: 800px;
  width: 100%;
  overflow: auto;
}

.btn {
  margin-top: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 206px;
  height: 36px;
  background-color: $main;
  outline: none;
  border: none;
  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  img {
    width: 15.4px;
    height: 15.4px;
    margin-left: 8.31px;
    filter: invert(0%) sepia(92%) saturate(31%) hue-rotate(200deg)
      brightness(140%) contrast(100%);
  }
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
}

.title_top {
  font-size: 2.6rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin: 0;
}
.status {
  margin-top: 16px;
  display: block;
  align-items: center;
  column-gap: 10.5px;
}

.price {
  margin-top: 17px;
  font-weight: bold;
}

.title {
  font-weight: bold;
  font-size: 1.8rem;
  color: #8d8d91;
  margin-bottom: 10px;
}

@media (max-width: 700px) {
  .modal {
    // width: 420px;
  }
  .top {
    display: block;
  }
  .info {
    margin-top: 15px;
  }
  .img {
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media (max-width: 500px) {
  .title_top {
    font-size: 1.4rem;
  }
}

@media (max-width: 375px) {
  .modal {
    width: 100%;
  }
}
