.press {
  width: 650px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  img {
    width: 100%;
    max-height: 85vh;
    object-fit: contain;
  }
  cursor: pointer;
}

.btnPrev,
.btnNext {
  z-index: 10;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.btnPrev {
  left: 0;
}
.btnNext {
  right: 0;
}

@media (max-width: 1000px) {
  .press {
    width: 650px;
  }
}

@media (max-width: 760px) {
  .press {
    width: 500px;
  }
}

@media (max-width: 575px) {
  .press {
    width: 300px;
  }
}

@media (max-width: 375px) {
  .press {
    width: 240px;
  }
}
