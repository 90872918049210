.product {
    position: relative;
    &__btn {
        width: 220px;
        height: 42px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        background-color: rgba(247, 247, 247, 0.25);
        cursor: pointer;
        &_text {
            margin-left: 16px;
            font-weight: bold;
            font-size: 1.3rem;
            line-height: 15px;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: #ffffff;
        }
    }
}

@media (max-width: 1160px) {
    .product{
        &__btn{
            width: 60px;
            &_text{
                display: none;
            }
        }
    }
}
