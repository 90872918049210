@import "/src/Styles/vars";

.modal {
  max-width: 530px;
  width: 100%;
  position: relative;
}

.btn {
  display: block;

  width: 250px;
  height: 42px;
  margin: 0 auto;
  margin-top: 25px;
  background-color: $main;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  border: none;
  outline: none;
  cursor: pointer;
}
.form {
  width: 100%;
}
.help_links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 23px;
  a:hover {
    font-weight: bold;
  }
}

.errorBack {
  display: block;
  text-align: center;
  color: #ff6e43;
  width: 100%;
  margin-bottom: 12px;
}
.line {
  width: 100%;
  border: 1px solid #e4e4e4;
}
