@import "../../../Styles/vars";

.slider {
  display: flex;
  // column-gap: 30px;
  // max-width: 675px;
  // width: 100%;
  // grid-column-gap: 0px;
}
.sliderModal {
  display: flex;
  max-width: 475px;
  column-gap: 10px;
}
.dots {
  width: 115px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  margin-right: 10px;
}

.dotsModal {
  width: 66px;
  .dot {
    width: 60px;
    height: 100px;
    background-color: #fff;
    border: 1px solid #fff;
    transition: all 0.5s;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.active {
  border: 1px solid $main;
}
.dot {
  width: 105px;
  height: 105px;
  background-color: #fff;

  transition: all 0.5s;
  cursor: pointer;
  padding: 3px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .active {
    border: 1px solid $main;
  }
}

.arrow {
  transition: all 0.1s;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: #fff;
  border: none;
  outline: none;
  padding: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: invert(50%) sepia(0%) saturate(2372%) hue-rotate(27deg)
      brightness(125%) contrast(77%);
  }
  &:hover {
    img {
      filter: none;
    }
  }
}

.prev_arrow {
  transform: rotate(180deg);
}

.slide,
.slideModal {
  // width: 100px;

  max-width: 280px;
  width: 100%;
  position: relative;

  &:hover {
    .slide__btn {
      opacity: 1;
    }
  }
  &__img {
    z-index: 2;
    width: 100%;
    // height: 100%;
    // object-fit: fill;
  }
  &__btn {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 42px;
    height: 42px;
    background-color: $main;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      z-index: 2;
      border-radius: 50%;
    }
  }
}
.slideModal {
  max-width: 300px;
  margin-right: 20px;
}
.nextArrow,
.prevArrow {
  display: flex;
  justify-content: center;
  cursor: pointer;
  :hover {
    path {
      fill: #ff6e43;
    }
  }
}
.prevArrow {
  transform: rotate(-180deg);
}
.nextArrow {
  margin-top: 10px;
}

.opportunities {
  margin-top: 66px;
  display: flex;
  justify-content: center;
  column-gap: 50px;
}
.btnPause,
.btnThreeD {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.icon {
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 12px;
}

@media (max-width: 1360px) {
  .dot {
    width: 80px;
    height: 80px;
  }
  // .dots {
  //   width: 80px;
  // }
  // .slide {
  //   width: 300px;
  //   height: 300px;
  // }
  .slider {
    // width: 380px;
  }
}

@media (max-width: 1100px) {
  .slider {
    margin: 0;
    margin-top: 15px;
    // position: relative;
    flex-direction: column-reverse;
    column-gap: 10px;
    align-items: center;

    max-width: none;
    .dots {
      margin-top: 15px;
      flex-direction: row;
      justify-content: center;
      column-gap: 10px;
      width: 100%;
    }
    .prev_arrow {
      transform: rotate(90deg);
    }
    .next_arrow {
      transform: rotate(-90deg);
    }
  }
  .opportunities {
    margin-top: 20px;
    justify-content: flex-start;
    flex-direction: column;
  }

  // .dots {
  //   margin-top: 15px;
  //   flex-direction: row;
  //   justify-content: center;
  //   column-gap: 10px;
  //   width: 280px;
  // }

  .dot {
    width: 100px;
    height: 100px;
  }
  .arrow {
    left: 300px;
  }
}

@media (max-width: 768px) {
  .slider {
    // max-width: 100%;
  }

  .dots {
    margin-top: 15px;
    flex-direction: row;
    justify-content: center;
    column-gap: 10px;
    width: 100%;
  }
  .sliderModal {
    display: flex;
    width: 100%;
    max-width: none;
    flex-direction: column-reverse;
  }
  .prev_arrow {
    transform: rotate(90deg);
  }
  .next_arrow {
    transform: rotate(-90deg);
  }
}

@media (max-width: 720px) {
  .prev_arrow {
    top: 20px;
  }
  .next_arrow {
    top: 375px;
  }
}

@media (max-width: 575px) {
  .prev_arrow {
    top: 0;
  }
  .next_arrow {
    top: 400px;
  }
}

@media (max-width: 460px) {
  // .slider {
  //   margin: 20px auto 100px;
  //   width: 280px;
  // }
  .slide {
    // max-height: 200px;
    img {
      height: 100%;
    }
  }
  // .dots {
  //   bottom: -140px;
  //   left: 52%;
  //   padding-bottom: 0;
  // }
  // .prev_arrow {
  //   top: 50px;
  // }
  // .next_arrow {
  //   top: 360px;
  // }
  // .arrow {
  //   left: 240px;
  // }
}

@media (max-width: 370px) {
  // .slider {
  //   margin: 120px auto 80px;
  //   width: 250px;
  // }
  // .slide {
  //   width: 250px;
  //   height: 250px;
  // }
  // .dots {
  //   bottom: -150px;
  //   width: 80px;
  // }
  .dot {
    width: 80px;
    height: 80px;
  }
  .prev_arrow {
    top: 25px;
  }
  .next_arrow {
    top: 295px;
  }
  .arrow {
    left: 220px;
  }
}
