@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 10px;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #e4e4e4;
    border: 1px solid #e4e4e4;
  }
  ::-webkit-scrollbar-thumb {
    background: #ff6e43;
  }
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  padding: 0;
}

li {
  list-style: none;
}

a {
  color: #000;
  text-decoration: none;
}

table {
  border-spacing: 0;
}
.header__fake {
  height: 121px;
  @media (max-width: 960px) {
    height: 100px;
  }
  @media (max-width: 575px) {
    height: 91px;
  }
}
.disabledLoading {
  cursor: progress !important;
}

.loader {
  position: relative;
  width: 100%;
  height: 100vh;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
