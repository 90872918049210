.menu {
  display: none;
  position: absolute;
  top: 55px;
  width: 100%;
  min-height: calc(100vh - 55px);
    // min-height: 100vh;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  ul:first-child {
    margin-top: 0;
  }

  &.active {
    display: block;
  }
}

.body {
  background-color: #fff;
}
.language {
  width: 80px;
  margin: 20px auto 0;
  padding-bottom: 20px;
}

.contacts {
  padding: 10px 20px;
  border-bottom: 1px solid #e4e4e4;
}

.phone {
  &_item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  &_link {
    margin-left: 25px;
    font-weight: 500;
  }
}

.auth {
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
  font-weight: 500;
}

.products {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: bold;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;
  &__menu {
    display: none;
    background-color: #fff;
    margin-left: 14px;
    max-height: 450px;
    overflow-y: scroll;
    &.active {
      display: block;
    }
    &__item {
      margin: 22px 0;
    }
  }
  &__submenu {
    display: none;
    background-color: #fff;
    &__link {
      display: block;
      font-size: 1.2rem;
      line-height: 14px;
      margin-bottom: 10px;
    }
    &.active {
      display: block;
    }
  }
  &__title {
    margin-top: 0;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    &__arrow {
      margin-left: 8.5px;
      filter: invert(80%) sepia(0%) saturate(0%) hue-rotate(273deg)
        brightness(30%) contrast(107%);
      transition: all 0.5s;
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  &__top {
    display: flex;
  }
  &__arrow {
    transition: all 0.3s;
    width: 12px;
    filter: invert(80%) sepia(0%) saturate(0%) hue-rotate(273deg)
      brightness(104%) contrast(107%);
    &.active {
      transform: rotate(90deg);
    }
  }
}

@media (max-width: 575px) {
  .products {
    &__top {
      &_img {
        width: 60px;
        height: 60px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    &__title {
      font-size: 1.1rem;
    }
  }
}
