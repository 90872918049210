.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 50px;
  color: #000000;
  padding: 150px 0;
  text-align: center;
  width: 100%;
  p {
    margin: 10px 0 40px;
    width: 100%;
  }
  span {
    margin: 10px 0;
  }
}

@media screen and (max-width: 425px) {
  .root {
    font-size: 60px;
    padding: 150px 20px;
  }
}
.groupBtn {
  display: flex;
  justify-content: center;
}
.tryAgain {
  margin-right: 20px;
}

.tryAgain,
.comeBack {
  max-width: 250px;
  width: 100%;
  height: 42px;
  background-color: #ff6e43;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
}
