@import "/src/Styles/vars";

.cart {
  position: relative;
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: -8px;
  padding: 1px 4px;
  color: #fff;
  background-color: $main;
  border-radius: 30px;
}

@media (max-width: 576px) {
  .count {
    top: -12px;
    right: -5px;
    font-size: 11px;
  }
}
