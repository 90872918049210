@import "./vars";

// .app {
//   width: 100%;
// }

.container {
  max-width: 1650px;
  margin: 0 auto;
  padding: 0 10px;

  &__1090px {
    max-width: 1090px;
    margin: 0 auto;
  }

  &__1100px {
    max-width: 1100px;
    margin: 0 auto;
  }

  &__1375px {
    max-width: 1375px;
    margin: 0 auto;
  }
}

.slick-arrow {
  position: absolute;
  top: -50px;
  right: 0;
  width: 38px;
  height: 38px;
  background-color: #000 !important;
  transition: all 0.3s;

  &:hover {
    background-color: $main !important;
  }
}

.slick-prev {
  padding-right: 1px;
  left: calc(100% - 120px);

  &:before {
    content: url("../Assets/Icons/slider_arrow_left.svg");
  }
}

.slick-next {
  right: 20px;
  padding-left: 1px;

  &:before {
    content: url("../Assets/Icons/slider_arrow_right.svg");
  }
}

@media (max-width: 1550px) {
  .container {
    padding: 0 25px;
  }
}

@media (max-width: 1100px) {
  .slick-arrow {
    bottom: -20px;
    top: inherit;
    z-index: 10;
  }
  .slick-prev {
    left: 50%;
    transform: translateX(-50% - 80);
  }
  .slick-next {
    right: 50%;
    transform: translateX(-50% + 180);
  }
  .container__1090px {
    padding: 0 20px;
  }
}

@media (max-width: 575px) {
  .consult-main {
    display: block !important;
  }
  .whyus {
    .slick-slider {
      .slick-arrow {
        bottom: -20px;
        top: inherit;
        z-index: 10;
      }

      .slick-prev {
        left: 60px;
      }

      .slick-next {
        right: 60px;
      }
    }
  }
}

@media (max-width: 500px) {
  .container__1090px {
    padding: 0 20px;
  }
}

@media (max-width: 500px) {
  .container {
    padding: 0 20px;
  }
}
