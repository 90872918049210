@import "src/Styles/vars";

.modal {
  text-align: center;
  width: 600px;
}

.text {
  margin: 20px 0;
  font-size: 2rem;
}

.btn {
  margin-top: 25px;
  input {
    width: 260px;
    height: 46px;
    border: none;
    outline: none;
    background-color: $main;
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
  }
}
