.burger {
  width: 17px;
  height: 13px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  margin-right: 30px;
  cursor: pointer;
  &__line {
    width: 11px;
    height: 1px;
    background-color: #fff;
    &.active {
      display: none;
    }
  }
  &__line_long {
    width: 17px;
    height: 1px;
    background-color: #fff;
    transition: all 0.5s;
    &:first-child.active {
      transform: translateY(5px) rotate(-45deg);
    }
    &:last-child.active {
      transform: translateY(-7px) rotate(45deg);
    }
  }
}

@media (max-width: 576px) {
  .burger {
    &__line {
      background-color: #8d8d91;
      height: 2px;
    }
    &__line_long {
      background-color: #8d8d91;
      height: 2px;
    }
  }
}
