@import "src/Styles/mixins";
@import "/src/Styles/vars";

.root {
  width: 100%;
  flex-direction: column;

  & label {
    font-size: 12px;
    line-height: 140%;
    color: rgba(9, 53, 79, 0.5);
  }

  & input {
    width: 100%;
    height: 61px;
    background-color: #fff;
    border: 1px solid #e4e4e4;
    outline: none;

    font-size: 1.4rem;
    padding: 19px 17px 26px;
    transition: all 0.5s ease;

    &:hover,
    &:focus,
    &:active {
      border: 1px solid $main;
    }
  }
  & .disabled {
    background-color: #f1f1f1;
  }
}

.required {
  // @include star-required();
}

.error {
  width: 100%;
  @include error-fildes();
  text-align: center;
  // span {
  //   margin-left: 10px;
  // }
}

.wrapperInput {
  position: relative;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  & .icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}

.errorInput {
  & input {
    border: 1px solid $colorError;
  }
}

.showHide {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 17px;
  &:hover {
    cursor: pointer;
  }
}
