@import "/src/Styles/vars";

.btn {
  display: block;
  width: 215px;
  height: 36px;
  margin: 30px auto 0;
  background-color: $main;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  border: none;
  outline: none;
  cursor: pointer;
}

.label {
  max-width: 410px;
  min-height: 42px;
  font-size: 1.4rem;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.03em;
  color: #656565;
  margin: 0 auto 27px;
}

.privacy_info {
  max-width: 500px;
  margin: auto;
  text-align: center;
  padding: 20px;
  font-size: 1.2rem;
  a {
    color: #ff6e43;
    font-weight: 600;
  }
}

@media (max-width: 575px) {
  .btn {
    width: 100%;
    height: 52px;
    margin-top: 0;
  }
  .label {
    width: 100%;
  }
}
.productName {
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;

  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: #222222;
}

.content {
  display: flex;
  align-items: flex-start;
  img {
    max-width: 100px;
    max-height: 120px;
    width: 100%;
    height: 100%;
    margin-right: 15px;
  }
}
